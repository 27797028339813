.vjs-modal-dialog-content {
  display: flex;
  align-items: center;
}

.vjs-error .vjs-error-display:before {
  content: '' !important;
}

.vjs-loading-spinner {
  display: none !important;
}
